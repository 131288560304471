::-ms-reveal {
    display: none;
}

.swal-z {
    z-index: 1500 !important;
}

.horizontal-lines {
    display: grid;
  grid-template: auto / auto 1fr;
  grid-gap: 0.5rem;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
  color: #121212;
}

.horizontal-lines:after {
    content: "";
    display: block;
    background: #121212;
    height: 0.0625rem;
    //margin-top: ;
  }

.customStepper{
  width: 100%;
  background: #000;
  padding-right: 0 !important;
  padding-left: 0 !important;
  border-radius: 100px;

}

/* input[type=checkbox]{
  accent-color: rgba(0, 155, 255, 1);
} */

input:checked[type=checkbox] {
  accent-color: rgb(68, 148, 227);
}